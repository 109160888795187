<template>
	<RadioGroup  v-model="selected" @on-change='radioChange'>	
		<Radio :disabled='disabled' :label="1">
			<span title="满意" :class="['item','like',selected == 1 ?'active':'']"></span>
		</Radio>
		<Radio :disabled='disabled' :label="0">
			<span title="一般" :class="['item','nomal',selected == 0 ?'active':'']"></span>
		</Radio>
		<Radio :disabled='disabled' :label="-1">
			<span title="不满意" :class="['item','dislike',selected == -1 ?'active':'']"></span>
		</Radio>		
	</RadioGroup>
</template>

<script>
	export default {
		name: 'imageRadio',
		props: ['value','disabled'],
		watch:{
			value(val){				
				this.selected = val
			}
		},
		data() {
			return {
				selected:undefined
			}
		},
		methods:{
			radioChange(e){
				this.$emit('radioChange',e)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item {
		width: 70px;
		height: 70px;
		display: inline-block;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% auto;
		opacity: 0.5;
		margin-left: 30px;
		margin-top: 10px;

		&.dislike {
			background-image: url(../assets/image/radio/-1.png);

			&.active {
				opacity: 1;
			}
		}

		&.nomal {
			background-image: url(../assets/image/radio/0.png);

			&.active {
				opacity: 1;
			}
		}

		&.like {
			background-image: url(../assets/image/radio/1.png);
			&.active {
				opacity: 1;
			}
		}
	}
	/deep/.ivu-radio{
		display: none !important;
	}
</style>
