<template>
	<div class="customerHome customerOrderEvaluation">
		<div class="chuck">
			<div class="title">
				{{edit?'用户评价':'查看评价'}}
			</div>
			<Form label-position="top" style="margin-top: 20px;" ref="evaluationForm" :model="evaluationForm"
				label-colon :rules="evaluationFormRules" inline>
				<FormItem style='width: 740px;' prop="qualityFeedbackStates" label="项目完成质量">
					<imageRadio :disabled='!edit' :value='evaluationForm.qualityFeedbackStates'
						@radioChange='selectQualityRate'></imageRadio>
					<span
						style="margin-left: 30px;color: red;font-size: 16px;font-weight: 500;">{{evaluationForm.qualityFeedbackStates | formatStates}}</span>

				</FormItem>
				<br>
				<FormItem style='width: 740px;' prop="progressFeedbackStates" label="项目流程满意度">
					<imageRadio :disabled='!edit' :value='evaluationForm.progressFeedbackStates'
						@radioChange='selectActionRate'></imageRadio>
					<span
						style="margin-left: 30px;color: red;font-size: 16px;font-weight: 500;">{{evaluationForm.progressFeedbackStates | formatStates}}</span>
				</FormItem>
				<br>
				<FormItem style='width: 740px;' prop="staffFeedbackStates" label="数据顾问满意度">
					<imageRadio :disabled='!edit' :value='evaluationForm.staffFeedbackStates'
						@radioChange='selectMemberRate'></imageRadio>
					<span
						style="margin-left: 30px;color: red;font-size: 16px;font-weight: 500;">{{evaluationForm.staffFeedbackStates | formatStates}}</span>
				</FormItem>
				<br>
				<FormItem style='width: 740px;' prop="feedbackReason" label="用户建议">
					<Input :disabled="!edit" maxlength="200" show-word-limit :rows="15" type="textarea"
						placeholder="用户建议" v-model="evaluationForm.feedbackReason" />
				</FormItem>
				<br>
				<FormItem v-if='edit' style='width: 740px;'>
					<Button class="btn" type="primary" @click="submit('evaluationForm')">提交</Button>
					<Button class="btn" style="margin-left: 20px;" type="default" @click="back()">返回</Button>
				</FormItem>
				<FormItem v-else style='width: 740px;'>
					<Button class="btn" type="primary" @click="back()">确定</Button>
				</FormItem>
			</Form>
		</div>
	</div>
</template>

<script>
	import imageRadio from '../../components/imageRadio.vue'
	import {
		data_feedback_submit,
		data_feedback_detail
	} from '../../api/customerApi.js'
	export default {
		name: 'customerOrderEvaluation',
		components: {
			imageRadio
		},
		data() {
			return {
				evaluationForm: {
					qualityFeedbackStates: '',
					progressFeedbackStates: '',
					staffFeedbackStates: '',
					feedbackReason: ''
				},
				evaluationFormRules: {
					qualityFeedbackStates: [{
						required: true,
						message: '必填项',
					}],
					progressFeedbackStates: [{
						required: true,
						message: '必填项',
					}],
					staffFeedbackStates: [{
						required: true,
						message: '必填项',
					}],
				}
			}
		},
		filters: {
			formatStates(val) {
				if (val == 1) {
					return '满意'
				} else if (val == 0) {
					return '一般'
				} else if (val == -1) {
					return '不满意'
				}
			}
		},
		computed: {
			edit() {
				return this.evaluationForm.hasOwnProperty('id') ? false : true
			},
			requireUid() {
				return this.$route.query.requireUid
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.getFeebBack()
			},
			async getFeebBack() {
				let data = await data_feedback_detail({
					requireUid: this.requireUid
				})
				if (data) {
					this.evaluationForm = data
				} else {
					this.evaluationForm = {
						qualityFeedbackStates: undefined,
						progressFeedbackStates: undefined,
						staffFeedbackStates: undefined,
						feedbackReason: ''
					}
				}
			},
			submit(name) {
				this.$refs[name].validate(async (valid) => {
					if (valid) {
						let params = this.evaluationForm
						params.requireUid = this.requireUid
						data_feedback_submit(params).then(res => {
							this.$msg.success({
								text: "反馈成功"
							})
							this.$refs[name].resetFields()
							setTimeout(() => {
								this.back()
							}, 1000)
						}).catch(err => {
							this.$msg.error({
								text: err
							})
						})
					} else {
						this.$msg.error({
							text: "请输入必填项"
						})
					}
				})
			},
			selectQualityRate(e) {
				this.selectRadio('qualityFeedbackStates', e)
			},
			selectActionRate(e) {
				this.selectRadio('progressFeedbackStates', e)
			},
			selectMemberRate(e) {
				this.selectRadio('staffFeedbackStates', e)
			},
			selectRadio(key, value) {
				this.evaluationForm[key] = value
			},
			back() {
				this.$router.push({
					path: '/customerorder/customerorder',
					query: {
						tab: 2
					}
				})
			}
		},

	}
</script>

<style lang="scss" scoped>
	@import "@/style/customerorder.scss";

	.btn {
		display: inline-block;
		width: 140px;
		height: 40px;
	}

	.chuck {
		min-height: calc(100vh - 120px);
	}
</style>
